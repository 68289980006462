import { Create, SimpleForm, TextInput } from 'react-admin';

const CloudCreate = props => (
    <Create {...props} style={{marginLeft: '20px'}}>
        <SimpleForm variant="outlined">
            <TextInput source="title" label="Name the cloud" />
        </SimpleForm>
    </Create>
)

export default CloudCreate
