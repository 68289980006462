import { List, Datagrid, TextField, EditButton } from 'react-admin';
import { useAuth0 } from '@auth0/auth0-react';
import Chip from '@material-ui/core/Chip'

const UserList = ({record}) => {
    return record.Users.map((user)=>{
        return <Chip size="small" color="primary" variant="outlined" label={user.username} style={{margin:'2px'}} />
    })
}

const CloudList = props => {

    const {
        user
    } = useAuth0();

    return (<List {...props} style={{ marginLeft: '20px' }} filter={{ user: user.id }}>
        <Datagrid>
            <TextField source="title" />
            <TextField source="description" />
            <TextField source="type" />
            <UserList />
            <EditButton label="" />
        </Datagrid>
    </List>)
}

export default CloudList