import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Handle } from 'react-flow-renderer';

const StyledEntity = styled('div')({
  background: '#fff',
  border: '1px solid #aaa',
  borderRadius: 5,
  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .2)',
  color: '#333',
  minHeight: 50,
  textAlign: 'center',
  fontSize: '12px',
  width: '160px',
  '.selected &': {
        boxShadow: 'inset 0 1px 7px 2px rgb(152 152 152 / 53%)',
        background: '#fcffdc',
        borderColor: '#2979ff'
  }
});

const StyledEntityHeader = styled('div')(props=>({
  background: props.headercolor || '#98a1e6',
  color: '#fff',
  padding: '2px 0px',
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px'
}));

const StyledEntityBody = styled('div')({
  padding: '10px'
});

const ExtraHandle = (props) => {
    let position, type
    if(props.id === 'd') {
        type = 'target'
        position = 'bottom'
    } else if (props.id === 'd1') {
        type = 'source'
        position = 'top'
    } else {
        return <i></i>
    }
    return (
     <Handle
        type={type}
        position={position}
        id={`handle-${props.id}`}
        style={{ background: 'transparent', border:'none'}} />
    )
}

const Entity = ({ data, id}) => {
  return (
    <div>
      <ExtraHandle id={id} />
      <Handle
        type="target"
        position="right"
        id="default-target"
        style={{ background: 'transparent', border:'none'}}
      />
      <StyledEntity>
        <StyledEntityHeader {...data}>{data.header}</StyledEntityHeader>
        <StyledEntityBody>{data.label}</StyledEntityBody>
      </StyledEntity>
      <Handle
        type="source"
        position="left"
        id="default-source"
        style={{ background: 'transparent', border:'none'}}
      />
    </div>
  );
};

export default Entity