import { fetchUtils } from 'react-admin';

export const dataProviderClient = (token) => {
    
    return (url, options={}) => {
        if (!options.headers) {
            options.headers = new Headers({ Accept: 'application/json' });
        }
        options.headers.set("Authorization", `Bearer ${token}`);
        return fetchUtils.fetchJson(url, options);
    }

}

