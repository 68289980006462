import { forwardRef } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';
import { useAuth0 } from '@auth0/auth0-react';

export const AuthWrapper = ({ children }) => {
  const {
    isAuthenticated,
    isLoading,
    error,
    loginWithRedirect,
    logout
  } = useAuth0();


  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message} <button onClick={logout({ returnTo: window.location.origin })}>try again</button></div>;
  }

  if(!isAuthenticated) {
    return loginWithRedirect()
  }
  return <>{children}</>;
}


export const LogoutButton = forwardRef((props, ref) => {
    
    const {
        logout,
        user
    } = useAuth0();

    const handleClick = () => (logout({ returnTo: window.location.origin }))
    return (
        <MenuItem
            onClick={handleClick}
            ref={ref}
        >
            <ExitIcon />  Logout {user.given_name}
        </MenuItem>
    );
});


export const authProvider = {
    // authentication
    login: params => Promise.resolve(),
    checkError: error => Promise.resolve(),
    checkAuth: params => Promise.resolve(),
    logout: () => Promise.resolve(),
    getIdentity: () => Promise.resolve(),
    // authorization
    getPermissions: params => Promise.resolve(),
};