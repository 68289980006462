import React from 'react';
import { getBezierPath, getMarkerEnd, getEdgeCenter, EdgeText } from 'react-flow-renderer';


const AssumptionEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  arrowHeadType,
  markerEndId,
}) => {
  const edgePath = getBezierPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  const [centerX, centerY] = getEdgeCenter({ sourceX, sourceY, targetX,targetY })

  const assumptions = (data || {}).assumptions || []
  const filtered = assumptions.filter(a=> a !== '').length
  let label = '-'
  if(filtered > 0) {
    label = filtered
  }

  return (
    <>
      <path id={id} style={style} className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd} />
      <EdgeText
        x={centerX}
        y={centerY}
        label={label}
        labelStyle={{ fill: '#444' }}
        labelShowBg
        labelBgPadding={[6, 4]}
        labelBgBorderRadius={100}
        />
    </>
  );
}

export default AssumptionEdge