import * as React from "react";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { styled } from '@material-ui/core/styles';
import { CloudState } from '../../state/CloudState'

const AccordianHeader = styled(AccordionSummary)({
    textTransform: 'uppercase',
    color: '#444',
    '&.Mui-expanded':{
        background: '#f5f9ff',
        '& p': {
            color: '#8ab4f3'
        }
    }
});


const Sentence = styled('div')({
    padding: '5px',
    paddingBottom: '10px',
    fontSize: '1.0em',
    fontWeight: 'normal',
    color: '#666',
    '& span': {
        color: '#000',
        background: '#ffffd8',
        padding: '2px',
        borderBottom: '1px dashed #ffd100',
        minWidth: '130px'
    },
    '& .goal': {
        background: '#f0ffee',
        borderColor: '#56b147'
    },
    '& .need': {
        background: '#f4f8ff',
        borderColor: '#84ace8'
    },
    '& .want': {
        background: '#fff6fe',
        borderColor: '#e07ad3'
    },
    '& .assumption': {
    }
});

const SentenceDivider = styled(Divider)({
    margin: '14px 0',
    background: '#eee'
})

const getLookupTable = (elements) => {
     const entityLabelHash = elements.map(element => {
        if(element.type !== 'entity') {
            return ['z','']
        }
        return [element.id, element.data.label]
    })

    return Object.fromEntries(entityLabelHash)
}

const AssumptionInsertion = (props) => {
    let label = props.assumption.label
    if(props.assumption.label === '' || !props.assumption.label) {
        label = '[assumption]'
    }
    return (<span className="assumption">{label}</span>)
}


const UseItInSentence = props => {

    const { elements, selected } = props

    let assumption = false
    if(selected.type === 'assumption') {
        assumption = selected.data.assumptions.find(a=>a.selected) || {text:'...'}
    }

    const lookup = getLookupTable(elements)

    const Lookup = ({entity}) => {
        let title, cls
        switch(entity) {
            case 'a':
                title = 'Entity A'
                cls = 'goal'
                break;
            case 'b':
                title = 'Entity B'
                cls = 'need'
                break;
            case 'c':
                title = 'Entity C'
                cls = 'need'
                break;
            case 'd':
                title = 'Entity D'
                cls = 'want'
                break;
            case 'd1':
                title = 'Entity D\''
                cls = 'want'
                break;
            default:
                title = 'Unknown Entity'
                cls = 'goal'
            break
        }
        return <span title={title} className={cls}>{lookup[entity]}</span> 
    }

    const SelectedSentence =() => {
        switch (selected.id) {
            case 'a':
                return (
                    <Paper>
                        <Box p={2}>
                            <Sentence>In order to <Lookup entity='a' /> we must <Lookup entity='b' /></Sentence>
                            <SentenceDivider />
                            <Sentence>In order to <Lookup entity='a' /> we must <Lookup entity='c' /></Sentence>
                        </Box>
                    </Paper>
                )
            case 'b':
                return (
                    <Paper>
                        <Box p={2}>
                            <Sentence>In order to <Lookup entity='a' /> we must <Lookup entity='b' /></Sentence>
                            <SentenceDivider />
                            <Sentence>If we want to <Lookup entity='b' /> we must <Lookup entity='d' /></Sentence>
                        </Box>
                    </Paper>
                )
            case 'b-a':
                return (
                    <Paper>
                        <Box p={2}>
                            <Sentence>In order to <Lookup entity='a' /> and if <AssumptionInsertion assumption={assumption} /> we must <Lookup entity='b' /></Sentence>
                            <SentenceDivider />
                            <Sentence>If we want to <Lookup entity='a' /> we must <Lookup entity='b' /> because <AssumptionInsertion assumption={assumption} /></Sentence>
                            <SentenceDivider />
                            <Sentence>Check that this assumption has words referring to both <span title='Entity A' className='goal'>Entity A</span> and <span title='Entity B' className='need'>Entity B</span></Sentence>
                            <SentenceDivider />
                            <Sentence>Check that this assumption explains the link and not one or both entities</Sentence>
                            <SentenceDivider />
                            <Sentence>Does <AssumptionInsertion assumption={assumption} /> reflect some mechanism through which <Lookup entity='b' /> can bring about <Lookup entity='a' />?</Sentence>
                        </Box>
                    </Paper>
                )
            case 'c-a':
                return (
                    <Paper>
                        <Box p={2}>
                            <Sentence>In order to <Lookup entity='a' /> and if <AssumptionInsertion assumption={assumption} /> we must <Lookup entity='c' /></Sentence>
                            <SentenceDivider />
                            <Sentence>If we want to <Lookup entity='a' /> we must <Lookup entity='c' /> because <AssumptionInsertion assumption={assumption} /></Sentence>
                            <SentenceDivider />
                            <Sentence>Check that this assumption has words referring to both <span title='Entity A' className='goal'>Entity A</span> and <span title='Entity C' className='need'>Entity C</span></Sentence>
                            <SentenceDivider />
                            <Sentence>Check that this assumption explains the link and not one or both entities</Sentence>
                            <SentenceDivider />
                            <Sentence>Does <AssumptionInsertion assumption={assumption} /> reflect some mechanism through which <Lookup entity='c' /> can bring about <Lookup entity='a' />?</Sentence>
                        </Box>
                    </Paper>
                )
            case 'c':
                return (
                    <Paper>
                        <Box p={2}>
                            <Sentence>In order to <Lookup entity='a' /> we must <Lookup entity='c' /></Sentence>
                            <SentenceDivider />
                            <Sentence>If we want to <Lookup entity='c' /> we must <Lookup entity='d1' /></Sentence>
                        </Box>
                    </Paper>
                ) 
            case 'd-b':
                return (
                    <Paper>
                        <Box p={2}>
                            <Sentence>If we want to <Lookup entity='b' /> and if <AssumptionInsertion assumption={assumption} /> we must <Lookup entity='d' /></Sentence>
                            <SentenceDivider />
                            <Sentence>If we want to <Lookup entity='b' /> we must <Lookup entity='d' /> because <AssumptionInsertion assumption={assumption} /></Sentence>
                            <SentenceDivider />
                            <Sentence>Check that this assumption has words referring to both <span title='Entity D' className='want'>Entity D</span> and <span title='Entity B' className='need'>Entity B</span></Sentence>
                            <SentenceDivider />
                            <Sentence>Check that this assumption explains the link and not one or both entities</Sentence>
                            <SentenceDivider />
                            <Sentence>Does <AssumptionInsertion assumption={assumption} /> reflect some mechanism through which <Lookup entity='d' /> can bring about <Lookup entity='b' />?</Sentence>
                        </Box>
                    </Paper>
                )
            case 'd1-c':
                return (
                    <Paper>
                        <Box p={2}>
                            <Sentence>If we want to <Lookup entity='c' /> and if <AssumptionInsertion assumption={assumption} /> we must <Lookup entity='d1' /></Sentence>
                            <SentenceDivider />
                            <Sentence>If we want to <Lookup entity='c' /> we must <Lookup entity='d1' /> because <AssumptionInsertion assumption={assumption} /></Sentence>
                            <SentenceDivider />
                            <Sentence>Check that this assumption has words referring to both <span title="Entity D'" className='want'>Entity D'</span> and <span title='Entity B' className='need'>Entity C</span></Sentence>
                            <SentenceDivider />
                            <Sentence>Check that this assumption explains the link and not one or both entities</Sentence>
                            <SentenceDivider />
                            <Sentence>Does <AssumptionInsertion assumption={assumption} /> reflect some mechanism through which <Lookup entity='d1' /> can bring about <Lookup entity='c' />?</Sentence>
                        </Box>
                    </Paper>
                )
            case 'd':
                return (
                    <Paper>
                        <Box p={2}>
                            <Sentence>If we want to <Lookup entity='b' /> we must <Lookup entity='d' /></Sentence>
                            <SentenceDivider />
                            <Sentence><Lookup entity='d' /> and <Lookup entity='d1' /> are in direct conflict</Sentence>
                            <SentenceDivider />
                            <Sentence><Lookup entity='d' /> puts <Lookup entity='c' /> in danger</Sentence>
                        </Box>
                    </Paper>
                )
            case 'd1':
                return (
                    <Paper>
                        <Box p={2}>
                            <Sentence>If we want to <Lookup entity='c' /> we must <Lookup entity='d1' /></Sentence>
                            <SentenceDivider />
                            <Sentence><Lookup entity='d1' /> and <Lookup entity='d' /> are in direct conflict</Sentence>
                            <SentenceDivider />
                            <Sentence><Lookup entity='d1' /> puts <Lookup entity='b' /> in danger</Sentence>
                        </Box>
                    </Paper>
                )
            default:
                return <Sentence>In order to x we must </Sentence>
        }

    }


    return (
        <div style={{width: '100%'}}>
            <Typography component="div">
                <SelectedSentence />
            </Typography>
        </div>
    )
}


const HelperSection = props => {

    const state = CloudState.useState(s => s);

    const [expanded, setExpanded] = React.useState(0);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const helpers = [
        {
           // selected: true,
            summary: 'Check logic',
            content: (<UseItInSentence elements={state.elements} selected={state.selected} />)
        },
        {
            summary: 'Checklist',
            content: (<Typography>Some example shared objectives</Typography>)
        },
        {
            summary: 'Examples',
            content: (<Typography>Some example shared objectives</Typography>)
        },
        {
            summary: 'How to',
            content: (<Typography>Some example shared objectives</Typography>)
        }
    ]

    return (

        <div style={{ marginBottom: '100px' }}>
            {helpers.map((value, index) => {
                return (
                    <Accordion key={`helper_${index}`} expanded={expanded === index} onChange={handleChange(index)}>
                        <AccordianHeader expandIcon={<ExpandMoreIcon />} >
                            <Typography>{value.summary}</Typography>
                        </AccordianHeader>
                        <AccordionDetails style={{paddingTop:'20px'}}>
                            {value.content}
                        </AccordionDetails>
                    </Accordion>
                )
            })}

        </div>

    )


}

export default HelperSection