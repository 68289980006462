import { Layout, AppBar, Sidebar, Menu, Notification, setSidebarVisibility } from 'react-admin';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';


const CustomSidebar = (props) => {
    return <Sidebar {...props} />
}

const CloudLayout = props => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setSidebarVisibility(false));
    });

    return <Layout
        {...props}
        appBar={AppBar}
        sidebar={CustomSidebar}
        menu={Menu}
        notification={Notification}
    />
};

export default CloudLayout