import { useEffect, useState } from "react";
import { useEditController, EditContextProvider } from 'react-admin';
import Toolbox from './toolbox/Toolbox'
import CloudPropertyInspector from './toolbox/CloudPropertyInspector'
import Diagram from './diagram/Diagram'
import { CloudState, initialCloudState } from '../state/CloudState'

const CloudBuilder = props => {

    const [loaded, setLoaded] = useState(false)
    const editControllerProps = useEditController(props);

    const { record } = editControllerProps

    useEffect(() => {
        if(record && record.elements) {
            CloudState.update(s => {
                if(record && record.elements) {
                    if(record.elements.length === 0) {
                        s.elements = initialCloudState
                    } else {
                        s.elements = record.elements
                    }
                }
            
                s.record = record
                setLoaded(true) // calling useState from inside?
                return s
            })
        } 
    }, [record, props.id]);

    if(!loaded) {
        return (<div>loading cloud...</div>)
    }

    return (
        <EditContextProvider value={editControllerProps}>
            <div>
                <Diagram />
                <Toolbox />
                <CloudPropertyInspector />
            </div>
        </EditContextProvider>
    );
}

export default CloudBuilder