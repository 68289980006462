
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import HelperSection from './HelperSection';
import EntityEditor from './EntityEditor'
import AssumptionEditor from './AssumptionEditor'

import { CloudState } from '../../state/CloudState';

const ShowEditor = props => {
    const selected = CloudState.useState(s => s.selected);
    if(selected.type === 'entity') {
        return <EntityEditor selected={props.selected} />
    } else {
        return <AssumptionEditor selected={props.selected} />
    }
}

const Toolbox = props => {

    const selected = CloudState.useState(s => s.selected);

    return (
        <Drawer anchor="bottom" open={selected ? true : false} variant="persistent">
            <Grid container style={{ height: '350px', overflow: 'hidden' }}>
                <Grid item md={6} sm={12}>
                    <ShowEditor />
                </Grid>
                <Grid item md={6} sm={12}>
                    <Box bgcolor="#f5f9ff" p={2} style={{ borderRight: '1px solid #cee1ff', height: '100%', maxHeight: '400px', overflow: 'auto' }}>
                        <HelperSection />
                    </Box>
                </Grid>
            </Grid>
        </Drawer>
    )
}

export default Toolbox
