import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';


ReactDOM.render(
    <React.StrictMode>
        <Auth0Provider
            domain="gravitybrands.us.auth0.com"
            clientId="qPqh9fllRjNNm9BQf9GZoX8XM7GbwLkL"
            redirectUri={window.location.origin}
        >
            
            <App />

        <style jsx="true" global="true">{`
            body {
                margin: 0px;
                padding: 0px;
            }
            .RaAppBar-menuButton-13 {
                margin-left: 5px;
            }
            .RaSidebar-drawerPaper-36 {
                border-right: 1px solid #c6dbff;
                background: #e8f1ff;
            }
        `}</style>
        </Auth0Provider>
    </React.StrictMode>
    ,
    document.getElementById('root')
);