import { useEffect, useState } from "react";
import { theme } from "./theme/global";
import { createBrowserHistory as createHistory } from 'history';
import { Admin, Resource } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';

import CloudIcon from '@material-ui/icons/CloudQueue';

import CloudList from './resources/cloud/components/crud/List'
import CloudCreate from './resources/cloud/components/crud/Create'
import CloudEdit from './resources/cloud/components/Cloud'
import CloudLayout from './theme/Layout'
import { useAuth0 } from "@auth0/auth0-react";

import { AuthWrapper, LogoutButton, authProvider } from './utils/auth0wrap'

import { CloudState } from './resources/cloud/state/CloudState'
import { dataProviderClient } from './utils/dataProviderClient'

const history = createHistory();

//put in config
const api = process.env.REACT_APP_PROD_API_URL || (process.env.REACT_APP_API_URL.replace('https://', `https://${process.env.REACT_APP_API_PORT}-`))

const App = () => {

    const { isAuthenticated, getAccessTokenSilently } = useAuth0()
    const [token, setToken] = useState(null)

    useEffect(() => {
        if (!token && isAuthenticated) {

            if(sessionStorage.getItem('auth0')) {
                const token = sessionStorage.getItem('auth0')
                setToken(token)
                CloudState.update(s => {
                    s.token = token
                    return s
                })
                return
            }

            getAccessTokenSilently({
                audience: 'fragrantjewelstoc',
                scope: 'admin:everything',
            }).then((result, err) => {
                setToken(result)
                sessionStorage.setItem('auth0', result);

                CloudState.update(s => {
                    s.token = result
                    return s
                })
            })
        }
    }, [ isAuthenticated, getAccessTokenSilently, token ])

    if (!token && isAuthenticated) {
        // need a token
        return (<div></div>)
    }

    const dataProvider = jsonServerProvider(api, dataProviderClient(token))

    return (
        <AuthWrapper>
            <Admin dataProvider={dataProvider} theme={theme} authProvider={authProvider} history={history} layout={CloudLayout} logoutButton={LogoutButton}>
                <Resource name="clouds" list={CloudList} create={CloudCreate} edit={CloudEdit} icon={CloudIcon} />
            </Admin>
        </AuthWrapper>
    )
};


export default App;