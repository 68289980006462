import { useState } from 'react'
import { List, arrayMove } from 'react-movable';
import { CloudState, commitSelected } from '../../state/CloudState';
import debounce from '../../../../utils/debounce'

import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

const delayedUpdate = debounce(commitSelected, 750)
const DragHandle = () => <DragHandleIcon style={{ cursor: 'grab', paddingTop: '6px', paddingRight: '7px', color: '#8cadde' }} />

const AssumptionItem = ({value, props, isDragged}) => {

const handleChange = (e) => {

        if(!value) {
            return
        }

        CloudState.update(s => {
            if (s.selected.data.assumptions.length === 0) {
                s.selected.data.assumptions = [{ text: '' }]
            } else if (s.selected.data.assumptions.length - 1 < props.key) {
                s.selected.data.assumptions.push({ text: '' })
            }
            s.selected.data.assumptions = s.selected.data.assumptions.map((ass, index) => {
                if (index === props.key) {
                    return { label: e.target.value, selected: true }
                }
                return { label: ass.label, selected: false }
            }).filter(e => e.label !== '')

            return s
        })
        delayedUpdate()
    }

    props.style.zIndex = 10000
    if(value.selected) {
        props.style = {
            borderBottom: '1px solid #ffd100',
            borderTop: '1px solid #ffd100',
            background: '#ffffdb',
            ...props.style
        }
    }
    props.tabIndex = 1000 
    return (<Box p={1} {...props}>
        <Box display="flex" flexDirection="row">
            <Box flexShrink={1}>
                <DragHandle />
            </Box>
            <Box flexGrow={6}>
                <TextField  InputLabelProps={{
                    shrink: true,
                    tabIndex: props.key
                }} label="Assumption" placeholder="Assumption" variant="outlined" size="small" value={value.label} fullWidth onChange={handleChange} onFocus={handleChange} style={{ background: 'white' }} />
            </Box>
        </Box>
    </Box>)

}


const AssumptionEditor = () => {

    const selected = CloudState.useState(s => s.selected);

    let assumptions = []
    if (selected.data && selected.data.assumptions) {
        assumptions = selected.data.assumptions.map(a => (a))
    }
    if (assumptions.length === 0) {
        assumptions = [{
            label: '',
            selected: true
        }]
    }

    if (assumptions[assumptions.length - 1].label !== '') {
        assumptions.push({
            label: ''
        })
    }


    return (
        <List
            values={assumptions}
            onChange={({ oldIndex, newIndex }) => {
                CloudState.update(s => {
                    s.selected.data.assumptions = arrayMove(assumptions, oldIndex, newIndex).filter(e => e.label !== '')
                    return s
                })
                delayedUpdate()
            }}
            renderList={({ children, props }) => <div style={{overflow: 'auto', maxHeight: '400px'}}><div {...props} style={{paddingBottom: '200px'}}>{children}</div></div>}
            renderItem={AssumptionItem}
        />
    );
};

const TabbedAssumptionWrapper = (props) => {

    const selected = CloudState.useState(s => s.selected);


    const [tabValue, setTabValue] = useState('edit')

    const handleTabChange = (e, newValue) => {
        setTabValue(newValue)
    }

    return (
        <TabContext value={tabValue}>
            <TabList onChange={handleTabChange}>
                <Tab label={`Edit  ${selected.id} Assumptions`} value="edit" />
                <Tab label="Notes" value="notes" />
            </TabList>
            <TabPanel value="edit" style={{ padding: 0, paddingTop: '20px' }}>
                <AssumptionEditor />
            </TabPanel>
            <TabPanel value="notes">scratch interface</TabPanel>
        </TabContext>
    )
}




export default TabbedAssumptionWrapper