import { Store } from "pullstate";
import debounce from '../../../utils/debounce'

const api = process.env.REACT_APP_PROD_API_URL || (process.env.REACT_APP_API_URL.replace('https://', `https://${process.env.REACT_APP_API_PORT}-`))

const assBase = {
    labelBgPadding: [6, 4],
    labelBgBorderRadius: 100,
    style: { stroke: '#333', strokeWidth: 2 },
    arrowHeadType: 'arrowclosed',
    '&.selected': {
        fill: '#f00'
    }
}

const assData = {
    data: { assumptions: [] },
}

export const initialCloudState = [
    {
        id: 'a',
        type: 'entity',
        data: { header: 'shared goal', headercolor: '#56b147', label: 'Entity A' },
        position: { x: 20, y: 100 },
        targetPosition: 'right'
    },
    {
        id: 'b',
        type: 'entity',
        data: { header: 'need', headercolor: '#b0caf1', label: 'Entity B' },
        position: { x: 260, y: 60 },
        sourcePosition: 'left',
        targetPosition: 'right'
    },
    {
        id: 'b-a',
        type: 'assumption',
        source: 'b',
        target: 'a',
        ...assData,
        ...assBase
    },
    {
        id: 'c',
        type: 'entity',
        data: { header: 'need', headercolor: '#b0caf1', label: 'Entity C' },
        position: { x: 260, y: 190 },
        sourcePosition: 'left',
        targetPosition: 'right'
    },
    {
        id: 'c-a',
        type: 'assumption',
        source: 'c',
        target: 'a',
        ...assData,
        ...assBase
    },
    {
        id: 'd',
        type: 'entity',
        data: { header: 'want', headercolor: '#e0aad9', label: 'Entity D' },
        position: { x: 560, y: 60 },
        sourcePosition: 'left'
    },
    {
        id: 'd-b',
        type: 'assumption',
        source: 'd',
        target: 'b',
        ...assData,
        ...assBase
    },
    {
        id: 'd1',
        type: 'entity',
        data: { header: 'want', headercolor: '#e0aad9', label: 'Entity D\'' },
        position: { x: 560, y: 190 },
        sourcePosition: 'left'
    },
    {
        id: 'd1-c',
        type: 'assumption',
        source: 'd1',
        sourceHandle: 'default-source',
        target: 'c',
        ...assData,
        ...assBase
    },
    {
        id: 'd-d',
        source: 'd1',
        target: 'd',
        type: 'ddedge',
        sourceHandle: 'handle-d1',
        targetHandle: 'handle-d',
        ...assData,
        style: { stroke: '#f94200', strokeWidth: 3 },
    }
]

export const CloudState = new Store({
    elements: initialCloudState,
    selected: false,
    token: false,
    record: false
});

let tmpFix = false
CloudState.createReaction(s => s.elements, (watched, draft, original) => {
    if(!tmpFix){
        tmpFix = true
        return
    }
    fetch(`${api}/clouds/${original.record.id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${original.token}`
        },
        body: JSON.stringify({
            elements: original.elements
        })
    })
})

let tmpFix2 = false
CloudState.createReaction(s => s.record, debounce((watched, draft, original) => {
    if(!tmpFix2){
        tmpFix2 = true
        return
    }
    fetch(`${api}/clouds/${original.record.id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${original.token}`
        },
        body: JSON.stringify({
            cloud: original.record
        })
    })
},1000))

export function commitSelected() {
    CloudState.update(s => {
        s.elements = s.elements.map(element => {
            if (s.selected.id === element.id) {
                return s.selected
            }
            return element
        })
        return s
    })
}

export const UserState = new Store({
    users: []
});

export const loadUsers = async () => {
    const result = await fetch(`${api}/users`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    const users = await result.json()
    UserState.update(u => {
        u.users = users
    })

}