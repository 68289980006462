
import ReactFlow, {
    Controls,
    Background
} from 'react-flow-renderer';

import '../../../../theme/diagram.css';

import Box from '@material-ui/core/Box';
import Entity from './Entity'
import DDEdge from './DDEdge'
import AssumptionEdge from './AssumptionEdge'

import { CloudState } from "../../state/CloudState";

const Diagram = props => {

    const elements = CloudState.useState(s => s.elements);

    const selectElement = (e, elements) => {
        const element = e && e.length > 0 ? e[0] : false;
        CloudState.update(s => {
            s.selected = element;
        })
    }

    return (
        <Box style={{height: '580px', width: '100%'}}>
            <ReactFlow 
                elements={elements} 
                elementsSelectable={true} 
                defaultZoom={1.3} 
                style={{ fontFamily: 'Roboto, sans-serif' }}
                arrowHeadColor='#333'
                nodeTypes={{ entity: Entity }}
                edgeTypes={{ ddedge: DDEdge, assumption: AssumptionEdge }}
                onSelectionChange={selectElement}
            >
                <Background color="#444" gap={16}  />
                <Controls style={{ top:'20px', right:'20px', left: 'auto', bottom: 'auto'}} />
            </ReactFlow>
        </Box>
    )
}

export default Diagram

