import { useState, useEffect } from "react";

import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';

import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CloudState, UserState, loadUsers } from '../../state/CloudState';

const Inspector = ({ open }) => {

    const users = UserState.useState(u => u.users);
    const record = CloudState.useState(s => s.record);

    useEffect(() => {
        loadUsers()
    }, [])

    const [tabValue, setTabValue] = useState('info')
    const handleTabChange = (e, newValue) => {
        setTabValue(newValue)
    }

    const setCloudTitle = (e) => {
        CloudState.update(s => {
            s.record.title = e.target.value
            return s
        })
    }

    const setCloudUsers = (e, selectedUsers) => {
        CloudState.update(s => {
            s.record.Users = selectedUsers
            return s
        })
    }

    let filteredUsers = []
    if(record.Users) {
        filteredUsers = users.filter(u=>{
            return record.Users.map(su=>su.id).includes(u.id)
        })
    }
    
    if (open && record.title) {
        return (
            <TabContext value={tabValue}>
                <TabList onChange={handleTabChange}>
                    <Tab label="Cloud Info" value="info" />
                    <Tab label="Context" value="context" />
                    <Tab label="History" value="history" />
                </TabList>
                <TabPanel value="info" lg={6}>
                    <Grid container>
                        <Grid item sm={12}>
                            <Box p={2}>
                                <TextField
                                    label="Cloud Name"
                                    variant="outlined"
                                    value={record.title}
                                    onChange={setCloudTitle}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item sm={12} >
                            <Box p={2}>
                                <Autocomplete
                                    multiple
                                    options={users}
                                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                                    //defaultValue={record.Users}
                                    onChange={setCloudUsers}
                                    value={filteredUsers}
                                    filterSelectedOptions
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => (
                                            <Chip
                                                variant="outlined"
                                                label={`${option.firstName} ${option.lastName}`}
                                                color="primary"
                                                {...getTagProps({ index })}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            variant="outlined"
                                            label="Who has access to this cloud?"
                                            placeholder="Just you"
                                        />
                                    )}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value="context">
                    <h3>Context</h3>
                </TabPanel>
                <TabPanel value="history">
                    <h3>undo history</h3>
                </TabPanel>
            </TabContext>)
    } else {
        return <></>
    }
}


const CloudPropertyInspector = props => {

    const selected = CloudState.useState(s => s.selected);

    const [open, setOpen] = useState(true)
    const toggleInspector = () => {
        setOpen(!open)
    }

    const Icon = open ? ArrowDropDownIcon : ArrowLeftIcon;

    return (
        <Drawer anchor="bottom" open={selected ? false : true} variant="persistent">
            <Grid container direction="row" style={{ height: open ? '350px' : '29px', overflow: 'hidden', borderTop: '1px solid #a7c4f5' }}>
                <Grid item style={{ flexGrow: 1 }}>
                    <Box style={{ width: '40%', minWidth: '400px' }}>
                        <Inspector open={open} />
                    </Box>
                </Grid>
                <Grid item>
                    <Icon style={{ fontSize: '1.8rem', cursor: 'pointer' }} onClick={toggleInspector} />
                </Grid>
            </Grid>
        </Drawer>
    )
}

export default CloudPropertyInspector