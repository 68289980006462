import { useState } from "react";


import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import TextField from '@material-ui/core/TextField';
import debounce from '../../../../utils/debounce'
import { CloudState, commitSelected } from '../../state/CloudState';

const delayedUpdate = debounce(commitSelected, 750)

const EntityEditor = props => {

    const [ tabValue, setTabValue ] = useState('edit')
    const selected = CloudState.useState(s => s.selected);

    const handleTabChange = (e, newValue) => {
        setTabValue(newValue)
    }

    const handleEntityInput = (e) => {
        CloudState.update(s => {
            s.selected.data.label = e.target.value
            return s
        })
        delayedUpdate() // prevent diagram from rerendering too much
    }

    return (<TabContext value={tabValue}>
        <TabList onChange={handleTabChange}>
            <Tab label={`Edit ${selected.id} Entity`} value="edit" />
            <Tab label="Notes" value="notes" />
        </TabList>
        <TabPanel value="edit">
            <TextField
                InputLabelProps={{
                    shrink: true,
                }}
                label="Entity Text"
                multiline
                rows={6}
                variant="outlined"
                fullWidth
                value={selected.data.label}
                onChange={handleEntityInput}
            />
        </TabPanel>
        <TabPanel value="notes">scratch interface</TabPanel>
    </TabContext>)
}

export default EntityEditor